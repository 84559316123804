import { getObjectValueByPath } from '@/utils/vuetify/helpers'

export class SearchFilter {
    customSearch: string[];

    constructor (customSearch: string[]) {
      this.customSearch = customSearch
    }

    searchInPaths (source: any, search: string) {
      for (const path of this.customSearch) {
        const valueAtPath = getObjectValueByPath(source, path)
        if (valueAtPath !== undefined && typeof valueAtPath !== 'object' && Array.isArray(valueAtPath) === false && this.matchPrimitive(valueAtPath, search)) {
          return true
        }
      }
      return false
    }

    matchPrimitive (current: string | number, search: string) {
      return (typeof current === 'string' && current.toLowerCase().includes(search.toLowerCase())) ||
            (typeof current === 'number' && current.toString().includes(search))
    }

    customFilter (value: any, search: string | null, item, searchObjectAlways = true) {
      return value && !searchObjectAlways ? this.searchInPaths(value, search) : this.searchInPaths(item, search)
    }
}
